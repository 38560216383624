<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Dotazníky - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.svy_id"
        :columns="columns"
        :loading="loading"
        :dataSource="surveys"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="svy_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/survey/detail/'+value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span
          slot="svy_selectable"
          slot-scope="record"
        >
        <span v-if="searchText">
            <template
              v-for="(fragment, i) in (parseInt(record.svy_selectable_min) + ' : ' + parseInt(record.svy_selectable_max)).toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{parseInt(record.svy_selectable_min)}} : {{parseInt(record.svy_selectable_max)}}</template>
        </span>
        <span
          slot="dates"
          slot-scope="text"
        >
        <span v-if="searchText">
            <template
              v-for="(fragment, i) in toLocaleDate(text).toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{toLocaleDate(text)}}</template>
        </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/survey/trend/'+record.svy_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <router-link :to="'/survey/detail/'+record.svy_id">
            <a-button icon="edit" size="small" class="mr-1">Upravit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.svy_id])" okText="Potvrdit" cancelText="Zrušit">
            <template slot="title">
                <p>Opravdu si přejete odstranit položku: #{{ record.svy_id }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ surveys.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/survey/add',
          title: 'Přidat',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'svy_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.svy_id - b.svy_id,
          scopedSlots: {
            customRender: 'svy_id',
          },
        },
        {
          title: 'Otázka',
          dataIndex: 'languages[0].pivot.sle_question',
          sorter: (a, b) => {
            return a.languages[0].pivot.sle_question.localeCompare(b.languages[0].pivot.sle_question)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Uživatel může zvolit (MIN:MAX) odpovědí',
          dataIndex: '',
          sorter: (a, b) => (a.svy_selectable_max + a.svy_selectable_min) - (b.svy_selectable_max + b.svy_selectable_min),
          scopedSlots: {
            customRender: 'svy_selectable',
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'created_at',
          sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Zveřejněno do',
          dataIndex: 'svy_date_show_until',
          sorter: (a, b) => new Date(a.svy_date_show_until) - new Date(b.svy_date_show_until),
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    surveys: function () {
      return this.$store.getters['survey/currentLanguage'](this.searchText)
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            this.selectedRowKeys = this.selectedRowKeys.length === 0 ? this.surveys.map(item => item.svy_id) : []
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('survey/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(surveys) {
      this.loading = true
      this.$store.dispatch('survey/delete', surveys)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = surveys.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !surveys.includes(x)))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
  },
  created() {
    if (this.surveys.length === 0 && this.$store.getters['language/active'] !== null) {
      this.handleRefresh()
    }
    this.$store.watch(
      (state, getters) => getters['language/active'], () => {
        if (this.$store.getters['language/active'] !== null && this.surveys.length === 0) {
          this.handleRefresh()
        }
      },
    )
  },
}
</script>

<style lang="scss" module scoped>

</style>
